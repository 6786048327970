<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="top-line position-relative">
          <div
            class="top-line__wrap"
            :style="{
              backgroundColor,
              backgroundImage: `url(${backgroundImage})`,
            }"
          >
            <div class="row">
              <div class="col-12 col-md-7">
                <h2 class="top-line__title">
                  <span v-html="title" />
                  <template v-if="showCity">
                    <span class="top-line__city">
                      <b @click="openCity = !openCity">
                        {{ getActiveCity.name }}</b
                      >

                      <svg
                        @click="openCity = !openCity"
                        width="26"
                        height="17"
                        viewBox="0 0 26 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.80397 0.0266515C1.45144 0.0362054 1.10891 0.147579 0.816599 0.347919C0.524286 0.548259 0.294323 0.829172 0.153708 1.1576C0.0130937 1.48603 -0.0323348 1.84842 0.0227556 2.20215C0.0778461 2.55589 0.231169 2.88634 0.464723 3.15467L11.5732 16.3397C11.7472 16.5467 11.9634 16.713 12.2068 16.827C12.4502 16.941 12.715 17 12.9831 17C13.2511 17 13.5159 16.941 13.7593 16.827C14.0027 16.713 14.2189 16.5467 14.3929 16.3397L25.5122 3.15467C25.6805 2.96844 25.8101 2.74968 25.8933 2.51148C25.9765 2.27328 26.0117 2.02051 25.9966 1.76823C25.9815 1.51594 25.9166 1.26923 25.8056 1.04299C25.6947 0.816746 25.54 0.61568 25.3508 0.451463C25.1616 0.287246 24.9418 0.163291 24.7045 0.0872148C24.4671 0.0111385 24.2171 -0.0155298 23.9694 0.00863444C23.7216 0.0327987 23.4812 0.107316 23.2625 0.227878C23.0437 0.348439 22.8512 0.512429 22.6962 0.710213L12.9812 12.2264L3.2699 0.710213C3.09207 0.491459 2.86785 0.316508 2.614 0.198139C2.36016 0.0797696 2.08324 0.0210449 1.80397 0.0266515Z"
                          fill="#FF6500"
                        />
                      </svg>
                      <ul class="top-line__list" v-if="openCity">
                        <li
                          v-for="city in getCityList"
                          :key="city.id"
                          :class="{ active: city.name === getActiveCity.name }"
                          @click="changeCityClick(city.id)"
                        >
                          {{ city.name }}
                        </li>
                      </ul>
                    </span>
                  </template>
                </h2>
              </div>
              <div class="col-12 col-md-5 position-relative">
                <div class="top-line__image-wrap">
                  <img :src="image" :alt="title" class="top-line__image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TopLineCity",
  props: {
    backgroundColor: {
      type: String,
      default: "#613EEA",
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: null,
    },
    showCity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openCity: false,
    };
  },
  computed: {
    ...mapGetters("config", ["getCity", "getCityList", "getActiveCity"]),
  },
  methods: {
    ...mapMutations("config", ["changeCity"]),
    changeCityClick(city) {
      this.openCity = false;
      this.changeCity(city);
    },
  },
};
</script>

<style scoped lang="scss">
.top-line {
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;

  &__wrap {
    min-height: 235px;
    border-radius: 10px;
    padding: 40px 40px 20px;
    height: 100%;
  }

  &__title {
    color: #ffffff;
    font-size: em(40);
    font-weight: 700;
    @include media(992) {
      font-size: em(32);
    }
    @include media(768) {
      font-size: em(24);
    }
  }

  &__image-wrap {
    min-height: 175px;
  }

  &__image {
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__city {
    color: #ff6600;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-left: 10px;
    svg {
      margin-left: 0.5vw;
    }
  }
  &__list {
    list-style: none;
    position: absolute;
    left: 0;
    min-width: 280px;
    z-index: 10;
    background-color: #fff;
    top: 100%;
    font-size: em(8);
    margin: 0;
    width: 100%;
    padding: 10px;
    color: #000;
    font-weight: normal;
    .active {
      font-weight: bold;
    }
  }
}
</style>
